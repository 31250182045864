<template>
    <div>
        <div class="mainList">
            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" v-if="permission(['DEPT_ADD'])" @click="add">新增</el-button>
                    </div>
                    <div class="mainList_content_operation-c">

                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>



                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable"
                              row-key="id"
                              border
                              :data="tableData"
                              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                              tooltip-effect="dark"
                              height="string"
                              :default-expand-all="false"
                              :stripe="elTable.stripe"
                              :border="elTable.border"
                              v-loading="loading"
                              header-cell-class-name="meterHeader"
                    >
                        <el-table-column v-for="(n,i) in tableColumnData" v-if="n.isShow" :fixed="n.fixed" :align="i==0?'left':'center'" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right" align="center" width="200" v-if="permission(['DEPT_DEL','DEPT_EDIT','DEPT_ADD'])">
                            <template slot-scope="scope">
                                <el-button type="text" @click="add(scope.row.id)" v-if="permission(['DEPT_ADD'])">新增</el-button>
                                <el-button type="text" class="edit" @click="edit(scope.row)" v-if="permission(['ROLE_EDIT'])">编辑</el-button>
                                <el-button type="text" class="del" @click="del(scope)" v-if="permission(['ROLE_DEL'])">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :listData="listData"></Edit>
    </div>
</template>
<script>
import {initTreeData} from '@/util/common'

import mixinsList from '@/mixins/list'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                del:this.$api['admin/dept'].del,
            },
            options:{
                parentId:0,
                typeId:0,
                isShow:1
            },
            listData: [],
            tableColumnData:[
                {prop:'deptName',label:'部门名称',isShow:true,sortable:false,fixed:false,},
                {prop:'leader',label:'负责人',isShow:true,sortable:false,fixed:false,},
                {prop:'phone',label:'联系电话',isShow:true,sortable:false,fixed:false,},
                {prop:'email',label:'邮箱',isShow:true,sortable:false,fixed:false,},
            ]
        };
    },
    created() {
        this.getData();
    },
    methods: {
        add(parentId){
            this.options = {
                parentId,
                status:0,
            };
            this.showEditDialog = true
        },
        getData() {
            this.loading = true;
            this.$api['admin/dept'].findByList().then(res => {
                this.loading = false;
                this.listData = res.data;
                this.tableData = initTreeData(res.data);
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
